import { cva } from 'class-variance-authority';
import React, { HTMLProps, useEffect, useState } from 'react';
import { cn } from '../../../../utils/common';

type ItemTypes = {
   label: string
   value: string
}

type Multiple = boolean | undefined;
interface ChipSelectProps {
   value?: string
   items: ItemTypes[]
   multiple?: Multiple
   selectedItems?: string[];
   selectedItem?: string;

   onSelect?: (selected: string | string[]) => void
}

const chipCls = cva(
   cn(
      'cursor-pointer font-regular rounded-lg px-3 py-1 text-sm',
   ),
   {
      variants: {
         selected: {
            true: 'bg-primary-100 text-white',
            false: 'bg-primary-100/20 text-gray-900'
         }
      }
   }
)

export const ChipSelect = React.forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement> & ChipSelectProps>((props, ref) => {

   const {
      selectedItem: selectedItemProps,
      selectedItems: selectedItemsProp,
      items = [],
      multiple = false,
      onSelect: onSelectProp,
      ...rest
   } = props;

   const [selectedItemLocal, setSelectedItem] = useState<string>('');
   const [selectedItemsLocal, setSelectedItems] = useState<string[]>([]);

   const selectedItem = selectedItemProps ?? selectedItemLocal;
   const selectedItems = selectedItemsProp ?? selectedItemsLocal;

   const onSelect = (arg: string) => {
      let updatedArr: string[] = selectedItems;

      if (multiple) {
         const exist = selectedItems.includes(arg);
         if (!exist) {
            updatedArr = [...selectedItems, arg];
         } else {
            updatedArr = updatedArr.filter((i) => i !== arg);
         }
      }
      if (typeof onSelectProp === 'function') onSelectProp(multiple ? updatedArr : arg)
      setSelectedItem(arg);
      setSelectedItems(updatedArr)
   }

   return (
      <div
         {...rest}
         ref={ref}
         className='flex flex-row items-center flex-wrap justify-center gap-2'
      >
         {items.map((item, index) => {

            return (
               <button
                  key={index}
                  onClick={() => onSelect(item.value)}
                  className={chipCls({
                     selected: !multiple ? item.value === selectedItem : selectedItems.includes(item.value)
                  })}
               >
                  {item.label}
               </button>
            )
         })}
      </div>
   )
})