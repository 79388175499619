import React, { HTMLProps, useEffect, useMemo, useState } from 'react';
import Layout from '../../components/common/Layout';
import { ProSectionContainer } from '../../components/common/ProSectionContainer';
import { ChipSelect } from '../../components/common/UI/ChipSelect';
import { BlogTypes } from './type';
import moment from 'moment';
import { Link, graphql, useStaticQuery, } from 'gatsby';

type CardProps = {
   item?: BlogTypes
}


const Card = React.forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement> & CardProps>((props, ref) => {

   const { item, ...rest } = props;

   const getAuthor = useMemo(() => {
      if (!item?.author?.node) return null;

      const { firstName, lastName, name, email } = item?.author?.node;
      return firstName ?? lastName ?? name ?? email;

   }, [item])

   return (
      <div
         className="group flex flex-col overflow-hidden rounded-lg bg-white shadow-lg dark:bg-xm-default-800"
         style={{ minHeight: 520 }}
         {...rest}
         ref={ref}
      >
         <div className="flex-shrink-0">
            <div className="h-[12rem] overflow-hidden">
               <img
                  className="w-full transform object-cover transition duration-500 ease-in-out group-hover:scale-105"
                  src="/images/cover-image.png"
                  alt=""
               />
            </div>
         </div>
         <div className="flex flex-1 flex-col justify-between p-6">
            <div className="flex-1">
               {/* tags */}
               <p className="overflow-hidden overflow-ellipsis whitespace-nowrap text-xm-default dark:text-xm-default-50">
                  {item?.tags?.edges?.map((tag, index) => {
                     return (
                        <span
                           key={index}
                           className="inline-flex items-center mr-2 last:mr-0 rounded-full bg-primary-100/20 px-3 py-0.5 text-xs font-medium leading-5 text-primary-200 "
                        >
                           {tag?.node?.name}
                        </span>
                     )
                  })}
               </p>

               <div className="mt-2 block overflow-hidden">
                  <div className="overflow-auto">
                     {item?.title && (
                        <p className="ellipsis-2-lines text-xl font-semibold text-gray-900 dark:text-xm-default-50">
                           {item?.title}
                        </p>
                     )}
                     <p
                        className="ellipsis-2-lines mt-3 text-base text-gray-700 dark:text-xm-default-50"
                        dangerouslySetInnerHTML={{ __html: item?.excerpt ?? '' }}
                     />
                  </div>
               </div>
            </div>
            <div className="flex-grow overflow-hidden">
               <div className="mt-6 flex items-center ">
                  <div className="flex-shrink-0">
                     <span className="sr-only">Hethal Solanki</span>
                     <img
                        alt=""
                        loading="lazy"
                        width={40}
                        height={40}
                        decoding="async"
                        data-nimg={1}
                        className="inline-block rounded-full "
                        src="https://previews.123rf.com/images/archivector/archivector1902/archivector190200317/117964202-abstract-sign-avatar-men-icon-male-profile-white-symbol-on-gray-circle-background-vector.jpg"
                        style={{ color: "transparent" }}
                     />
                  </div>
                  <div className="ml-3">
                     <p className="text-sm text-gray-800 font-medium mb-0 text-xm-default dark:text-xm-default-50">
                        {getAuthor}
                     </p>
                     <div className="flex space-x-1 text-sm text-gray-500 dark:text-xm-default-300">
                        <time dateTime="2024-07-17">
                           {item?.date && (
                              moment(item?.date).utc().format('llll')
                           )}
                        </time>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
})

type PageProps = {
   data?: BlogTypes[]
   assetNodes: {
      enqueuedScripts: { nodes: { src: string }[] },
      enqueuedStyleSheets: { nodes: { src: string }[] }
   }[]
}

const Page = (props: PageProps) => {

   const { data = [] } = props;

   const [posts, setPosts] = useState<BlogTypes[]>([]);
   const [persisted, setPersisted] = useState<BlogTypes[]>([]);
   const [selected, setSelected] = useState<string>('all');
   const [tagList, setTagList] = useState<any[]>([]);

   const chipOptions = useMemo(() => {
      return [{ label: 'ALL', value: 'all' }].concat(tagList)
   }, [tagList])

   const onSelectType = (arg: string) => {
      setSelected(arg)
   }

   useEffect(() => {
      setPosts(data);
      setPersisted(data);

      /* build the tag list */
      const tags = data
         .map(({ tags }) => (
            tags?.edges?.map(({ node: { name: label, id: value } }) => ({
               label, value
            }))
         ))
         .flatMap((i) => i);


      setTagList(tags.filter((i, index) => {
         const uniqueIndex = tags.findIndex((e) => {
            return e?.value === i?.value
         })
         return uniqueIndex === index;
      }));
   }, [data])

   useEffect(() => {

      if (selected !== 'all') {
         const filtered = persisted.filter((i) => (
            i.tags?.edges?.map((edge) => edge.node.id).includes(selected)
         ))
         setPosts(filtered)
      } else {
         setPosts(persisted);
      }

   }, [selected, persisted])


   return (
      <Layout>

         <div className='layout'>
            <ProSectionContainer
               title={'From the Blog'}
               description='Company Updates & Technology Articles'
               nodeType='html'
               extraNodes={
                  <>
                     <div className='flex mt-3  flex-col justify-center gap-5 items-center'>
                        <ChipSelect
                           items={chipOptions}
                           onSelect={(e) => onSelectType(e)}
                           selectedItem={selected}
                        />
                        <div className='mt-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5'>
                           {posts.map((item, index) => {

                              return (
                                 <Link to={`/blogs/${item?.slug}?`} state={{
                                    stylesheets: item?.enqueuedStylesheets?.nodes ?? []
                                 }}>
                                    <Card item={item} key={index} />
                                 </Link>
                              )
                           })}
                        </div>
                     </div>
                  </>
               }
            />
         </div>
      </Layout>
   )
}

const Blogs = () => {

   const data = useStaticQuery(graphql`
   query HeaderQuery {
     wp {
      pages {
         nodes {
           id
           uri
           enqueuedStylesheets {
             nodes {
               src
             }
           }
           enqueuedScripts {
             nodes {
               src
               id
             }
           }
         }
       }
       posts {
         nodes {
           slug
           title
           date
           uri
           authorId
           content
           author {
             node {
               email
               name
               lastName
               firstName
             }
           }
           enqueuedStylesheets {
            nodes {
              src
            }
           }
           excerpt
           content
           enqueuedStylesheets {
            nodes {
              src
            }
           }
           id
           tags {
             edges {
               node {
                 id
                 description
                 name
               }
             }
           }
         }
       }
     }
   }
 `)

   if (!data) return 'No Post Available'

   return (
      <Page
         data={data?.wp?.posts?.nodes}
         assetNodes={data?.wp?.pages?.nodes}
      />
   )
}

export default Blogs;